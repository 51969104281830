<template>
    <div class="release" style="width: 100%">
        <el-dialog :visible.sync="showTags" width="900px" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="top">
                编辑内容
                <img src="../../../assets/imgs/circle/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="exit_save()">
            </div>
            <div style="padding: 10px 30px 30px 30px">
                <div class="input_box fade" v-loading="loading">
                    <el-form label-width="100px" label-position="left" >
                        <div class="input_box_textarea" style="margin-bottom:8px; margin-left: 10px">
                            <div style="display: flex; margin-top: 10px">
                                <div style="margin-right: 20px;" v-html="topicName"></div>
                                <el-radio-group v-if="at_open == false && at_user_list.length <= 0" v-model="common_type" @input="checkoutAuth" style="line-height: 30px">
                                    <el-radio :label="4">全部可见</el-radio>
                                    <el-radio :label="3">仅粉丝可见</el-radio>
                                    <el-radio :label="2">仅好友可见</el-radio>
                                    <el-radio :label="1">仅自己可见</el-radio>

                                </el-radio-group>
                            </div>
                            <div class="input_box_title" style="width:98%;">
                                <el-input
                                    type="textarea"
                                    resize="none"
                                    :rows="8"
                                    placeholder="记录每时每刻的灵感与思考"
                                    v-model="content"
                                    :autosize="{ minRows: 8, maxRows: 10 }"
                                    :show-word-limit="true"
                                    minlength="10"
                                    @input="content_input"
                                    style="margin-top: 10px; display: inline-block">
                                </el-input>
                            </div>

                            <!--艾特好友-->
                            <div class="input_box_textarea" style="margin-top:10px; width: 96%;"  v-if="at_open">
                                <div class="at" style="height: 50px;background:  #FAFAFA;padding: 10px;">
                                    <el-tag type="info" style="color:#031F88;margin:2.5px 5px;" v-for="(item,index) in at_user_list" :key="index" size="mini" closable @close="removeAt(index)"> @{{item.friendInfo.nickName}}</el-tag>
                                </div>
                                <!--                 <el-select v-model="at_user_index" filterable :remote-method="get_all_user_list" loading-text="加载中" :remote="true" placeholder="输入或选择用户" @change="add_at_user">
                                                    <el-option v-for="(item,index) in all_user_list" :key="index" :label="item.nick_name" :value="index">
                                                    </el-option>
                                                </el-select> -->
                                <div style="overflow: hidden;height: 40px;line-height: 40px;background:  #FAFAFA;padding:  0 10px; border-top:1px dashed #ccc;">
                                    <div style="float: left;">您可以@以下好友</div>
                                    <div style="float: right;"><el-button type="primary" size="small" @click="at_open = false">确定</el-button></div>
                                </div>
                                <div style="overflow:auto;margin-top: 10px;">
                                    <div v-for="(item,index) in all_user_list" :key="index">
                                        <div class="at_user hand op sc " :class="{'active':item.check}" @click="set_at_user(item.id,item.friendInfo.nickName,index)" >
                                            <div style="float: left; width: 30px;height:30px;margin-top: 5px; ">
                                                <img :src="item.friendInfo.headIco + '-80x80'" style="width:100%;height:100%;" />
                                            </div>
                                            <div style="float: left;line-height: 40px;margin: 0 5px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width:50px;">{{item.friendInfo.nickName}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 发布选项展示 -->
                            <div class="input_box_btn_list" style="font-size: 16px">
                                <div class="input_box_btn op sc hand" @click="set_upload_img_open" style="position: relative; margin-right: 8px">

                                    <div class="input_box_btn_icon" style="margin-top: 4px"><img src="../../../assets/imgs/circle/picture.png"/></div>
                                    <div class="input_box_btn_name" style="">图片</div>
                                    <el-badge :value="imgs.length" style="position:absolute; z-index:10; left:45px;bottom:5px;" v-if="imgs.length > 0"></el-badge >
                                </div>

                                <div class="input_box_btn op sc hand" @click="set_upload_video_open"  style="position: relative; margin-right: 8px">
                                    <div class="input_box_btn_icon" style="margin-top: 4px"><img src="../../../assets/imgs/circle/video.png"/></div>
                                    <div class="input_box_btn_name">视频</div>
                                    <el-badge :value="videos.length" style="position:absolute; z-index:10; left:45px;bottom:5px;" v-if="videos.length > 0"></el-badge >
                                </div>

                                <div class="input_box_btn op sc hand" @click="set_at_open" style=" margin-right: 8px">
                                    <div class="input_box_btn_icon" style="margin-top: 4px"><img src="../../../assets/imgs/circle/at.png"/></div>
                                    <div class="input_box_btn_name">AT</div>
                                </div>

                                <div v-if="data.type == 1" class="input_box_btn op sc hand" style=" margin-right: 8px">
                                    <el-dropdown trigger="click"  @command="handleCommand" style="height:40px;line-height: 40px;" placement="bottom-start">
                                        <div>
                                            <div class="input_box_btn_icon" style="margin-top: 4px"><img src="../../../assets/imgs/circle/link.png"/></div>
                                            <div class="input_box_btn_name" style="font-size: 16px">链接</div>
                                        </div>
                                        <el-dropdown-menu slot="dropdown">
                                            <div style="overflow: hidden; width: 320px;">
                                                <el-input v-model="linkConent" placeholder="请输入链接地址" style="width: 260px; margin: 0 10px"></el-input>
                                                <img src="../../../assets/imgs/circle/send.png" class="hand op sc" style="width: 24px; height: 24px; vertical-align: middle"/>
                                            </div>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>

                                <div class="input_box_btn op sc hand" @click="set_upload_file_open" style="position: relative; margin-right: 8px">
                                    <div class="input_box_btn_icon" style="margin-top: 4px"><img src="../../../assets/imgs/circle/files.png"/></div>
                                    <div class="input_box_btn_name" style="">附件</div>
                                    <el-badge :value="files.length" style="position:absolute; z-index:10; left:45px;bottom:5px;" v-if="files.length > 0"></el-badge >
                                </div>

                                <div v-if="common_type == 4" class="input_box_btn op sc hand"  style="position: relative; margin-right: 8px">
                                    <el-dropdown trigger="click"  @command="handleCommandTopic" style="height:40px;line-height: 40px;">
                                        <div >
                                            <div class="input_box_btn_icon" style="margin-top: 2px;"><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 448 512" width="20" height="18" style="fill: rgb(90, 111, 230);"><path d="M440.667 182.109l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l14.623-81.891C377.123 38.754 371.468 32 363.997 32h-40.632a12 12 0 0 0-11.813 9.891L296.175 128H197.54l14.623-81.891C213.477 38.754 207.822 32 200.35 32h-40.632a12 12 0 0 0-11.813 9.891L132.528 128H53.432a12 12 0 0 0-11.813 9.891l-7.143 40C33.163 185.246 38.818 192 46.289 192h74.81L98.242 320H19.146a12 12 0 0 0-11.813 9.891l-7.143 40C-1.123 377.246 4.532 384 12.003 384h74.81L72.19 465.891C70.877 473.246 76.532 480 84.003 480h40.632a12 12 0 0 0 11.813-9.891L151.826 384h98.634l-14.623 81.891C234.523 473.246 240.178 480 247.65 480h40.632a12 12 0 0 0 11.813-9.891L315.472 384h79.096a12 12 0 0 0 11.813-9.891l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l22.857-128h79.096a12 12 0 0 0 11.813-9.891zM261.889 320h-98.634l22.857-128h98.634l-22.857 128z"></path></svg></div>
                                            <div class="input_box_btn_name" style="font-size: 16px;color: #000000;">话题</div>
                                        </div>
                                        <el-dropdown-menu slot="dropdown" >
                                            <div style="overflow: hidden; width: 270px; ">
                                                <div style="overflow-y: scroll;width: 280px;height:400px;">
                                                    <el-input v-model="topic_like" style="width:90%; padding: 0 5%;" placeholder="请选择或搜索所属话题" @input="search_topic"></el-input>
                                                    <el-dropdown-item  :command="item" v-for="item in top_topic_list " :key= "item.id" >
                                                        <div style="overflow: hidden;">
                                                            <div style="float: left;">#{{item.name}}#</div>
                                                            <div style="float: right;">{{item.clickcount}}</div>
                                                        </div>
                                                    </el-dropdown-item>
                                                </div>
                                            </div>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>

                                <div class="input_box_btn op sc hand" style="position: relative; margin: 0 8px">
                                    <el-checkbox v-model="reply_type" :true-label="2" :false-label="1">禁止评论</el-checkbox>
                                </div>

                                <div class="input_box_btn op sc hand" style="position: relative; margin-right: 2px" v-if="common_type == 4">
                                    <el-checkbox v-model="isReprinted" :true-label="2" :false-label="1">禁止转发</el-checkbox>
                                </div>

                                <div style="display: flex; justify-content: end; margin-bottom: 10px; margin-right: 20px">
                                    <div class="hand op sc">
                                        <el-button type="primary" @click="save" style="width: 80px; height: 32px; background-color: #2970FF;border-color:#FFFFFF; font: 15px Microsoft YaHei; line-height: 1px">发布</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>

        <!--编辑知识仓库格式图片-->
        <el-dialog :close-on-click-modal="false" width="482px" :visible.sync="menuImagesShow" :show-close=false class="upload_att">
            <menu-image :is-show="menuImagesShow" :images="imgs"  @custom-event="imageCustomEvent" :on-close="closeImageModule"></menu-image>
        </el-dialog>

        <!--编辑知识仓库格式视频-->
        <el-dialog :close-on-click-modal="false" width="482px" :visible.sync="menuVideoShow" :show-close=false class="upload_att">
            <menu-video :video="videos"  @custom-event="closeViodeModule"></menu-video>
        </el-dialog>

        <!--发布知识仓库格式附件-->
        <el-dialog :close-on-click-modal="false" width="705px" :visible.sync="menuEnclosureShow"  :show-close=false class="upload_att">
            <menu-enclosure :data="files"  @custom-event="closeEnclosureModule"></menu-enclosure>
        </el-dialog>
    </div>
</template>

<script>
    import menuImage from "./menuModule/menuImage";
    import menuVideo from "./menuModule/menuVideo";
    import menuEnclosure from "./menuModule/menuEnclosure";
    export default {
        name: "InputBox",
        components: {
            menuImage,
            menuVideo,
            menuEnclosure
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {}
                }
            },
        },
        data() {
            return {
                showTags: true,
                loading:false,
                imgs:[],
                videos:[],
                files:[],
                content: "",
                common_type:4,
                reply_type:1,
                isReprinted:1,
                at_user_list: [],
                all_user_list:[],
                at_open: false,
                img_length:0,
                upload_qrcode:"https://baigongbao.oss-cn-beijing.aliyuncs.com/img/xcx_qrcode.jpg-80*80",
                topic_str:"",
                linkConent: '',
                //话题 字段
                top_topic_list:[],
                topic_like: "",
                topicName:"",
                topicId:0,

                menuImagesShow:false,
                menuVideoShow:false,
                menuEnclosureShow:false,

            }
        },
        mounted() {
            var that = this;
            switch (that.data.bgbType)
            {
                case 3:that.imgs = that.data.images;
                    break;
                case 4:that.videos = that.data.videos;
                    break;
                case 5:that.files = that.data.enclosure;
                    break;
            }
            that.content = that.data.content;
            that.reply_type = that.data.isComment;
            that.isReprinted = that.data.isReprinted;
            that.linkConent = that.data.link;
            that.common_type = that.data.auth;
            that.getFriendGroups().then((res)=>{
                that.dealFriends(res);
            }); //获取好友
            this.getTopTopicList();  //话题列表
        },
        methods: {
            //关闭添加图片组件
            closeImageModule(){
                this.menuImagesShow = false;
            },
            //接受图片组件的值
            imageCustomEvent(data){
                console.log(data.images);
                this.imgs = data.images;
            },

            //关闭添加视频组件
            closeViodeModule(data) {
                console.log(data.videos);
                this.menuVideoShow = false;
                this.videos = data.videos;
            },
            //关闭添加附件组件
            closeEnclosureModule(data){
                console.log(data.files);
                this.menuEnclosureShow = false;
                this.files = data.files;
            },

            // 切换权限
            checkoutAuth(e) {
                if (e !== 4) {
                    this.topicName = '';
                    this.topicId = 0;
                    this.isReprinted = 2
                } else {
                  this.isReprinted = 1
                }
            },

            //获取话题列表
            getTopTopicList:function(){
                var that = this;
                that.newApi.getTopTopicList({}).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.top_topic_list = ret.data;
                        that.topicId = that.data.topicId;
                        for (let i = 0; i < that.top_topic_list.length; i++) {
                            if(that.top_topic_list[i].id == that.topicId){
                                that.topicName ="#" + that.top_topic_list[i].name + "#";
                            }
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },

            //话题搜索及显示
            search_topic(){
                var that = this;
                if (that.topic_like !="") {
                    let param = {};
                    param.like = that.topic_like;
                    that.newApi.getTopTopicList(param).then((ret)=>{
                        if(ret.isSuccess == 1){
                            that.top_topic_list = ret.data;
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }else{
                    that.getTopTopicList();
                }
            },

            handleCommandTopic(obj) {
                this.topicName = "#" + obj.name + "#";
                this.topicId = obj.id;
            },

          // 点上传图片
          set_upload_img_open() {
            if (this.linkConent) {
              this.utils.msg('图片类型禁止携带链接')
              return false;
            }
            if(this.videos.length > 0 || this.files.length > 0){
              this.utils.err("图片、视频、文件只能上传一种");
              return false;
            }
            this.menuImagesShow = true;
          },
          // 点上传视频
          set_upload_video_open(){
            if (this.linkConent) {
              this.utils.msg('视频类型禁止携带链接')
              return false;
            }
            if(this.imgs.length > 0 || this.files.length > 0){
              this.utils.err("图片、视频、文件只能上传一种");
              return false;
            }
            this.menuVideoShow = true;
          },
          // 点击上传附件
          set_upload_file_open() {
            if (this.linkConent) {
              this.utils.msg('附件类型禁止携带链接')
              return false;
            }
            if(this.imgs.length > 0 || this.videos.length > 0){
              this.utils.err("图片、视频、文件只能上传一种");
              return false;
            }
            this.menuEnclosureShow = true;
          },


            // 艾特好友展示
            set_at_open() {
              this.at_open = !this.at_open;
              this.common_type = 4;
            },

            // 获取好友
            getFriendGroups() {
                var that = this;
                return new Promise(function (resolve) {
                    that.all_user_list = [];
                    that.newApi.getMyFriendGroupList({}).then(res =>{
                        for (const item of res.data) {
                            for (const friends of item.friendArr) {
                                that.all_user_list.push(friends)
                            }
                        }
                        for (var i = 0; i < that.all_user_list.length; i++) {
                            that.all_user_list[i]['num'] = i
                        }
                        resolve(that.all_user_list);
                    })
                })
            },

            //处理已经@的好友
            dealFriends(){
                var that = this;
                //已经@的人
                let at = that.data.aT;
                if(at.length > 0){
                    for (let i = 0; i < at.length; i++) {
                        at[i]["num"] = i;
                        at[i].check = true;
                        at[i].friendInfo = {"nickName":at[i].nickName,"uuid":at[i].uuid};
                    }
                }

                that.at_user_list = at;
                for (let i = 0; i < that.all_user_list.length ; i++) {
                    for (let j = 0; j < that.at_user_list.length; j++) {
                        if(that.all_user_list[i].friendInfo.nickName == that.at_user_list[j].nickName){
                            that.all_user_list[i].check = true;
                        }
                    }
                }
            },

            // 移除好友
            removeAt(index) {
                var aindex = this.at_user_list[index].num ;
                this.all_user_list[aindex].check = false;
                this.at_user_list.splice(index,1);
            },

            // 配置用户
            set_at_user(id,nickName,index){
                var that = this;
                console.log(this.all_user_list[index].check);
                if (that.all_user_list[index].check) {
                    that.all_user_list[index].check = false;
                    for (var i = 0; i < that.at_user_list.length; i++) {
                        if (that.at_user_list[i].friendInfo.nickName == nickName) {
                            that.at_user_list.splice(i,1);
                        }
                    }
                }else{
                    console.log(456);
                    that.all_user_list[index].check = true;
                    this.at_user_list.push(that.all_user_list[index]);
                    console.log(that.at_user_list);
                }
            },

            handleCommand(command) {
                this.topic = command;
                this.topic_str = "#" +command +"#" ;
                this.placeholder = "#" +command +"#" ;
            },

            // 富文本输入
            content_input(e) {
                var string = e;
                string = string.replace(/<p>/g, "").replace(/<\/p>/g, "");
                string = string.charAt(string.length - 1);
                if (string == "@") {
                    this.at_open = true;
                    this.common_type = "4";
                }
            },

            get_business_card_list(){
                var that = this;
                this.api.getFriendCardList().then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].check = false;
                        res.data[i].num = i;
                    }
                    that.all_user_list = res.data;
                    console.log(that.all_user_list)
                });
            },
            // 发布
            save() {
                var params = {};
                var that = this;
                params.id = that.data.id;
                params.content = this.content;
                if(that.content == ""){
                    that.utils.err("请填写动态文字描述");
                    return false;
                }
                params.auth = this.common_type;
                if(that.imgs.length == 0 && that.videos.length == 0 && that.files.length == 0 && that.content != ""){
                    params.type = 1;
                }
                params.topicId = that.topicId;
                params.type = that.data.type;
                if(params.type == 3 && that.imgs.length == 0){
                    that.videos = [];
                    that.files = [];
                    that.utils.msg("请上传最少一张图片!");
                    return false;
                }else if(params.type == 4 && that.videos.length == 0){
                    that.imgs = [];
                    that.files = [];
                    that.utils.msg("请上传最少一个视频!");
                    return false;
                }else if(params.type == 5 && that.files.length == 0){
                    that.imgs = [];
                    that.videos = [];
                    that.utils.msg("请上传最少一个附件!");
                    return false;
                }
                params.isComment = that.reply_type;
                params.isReprinted = that.isReprinted;
                params.operationType = 2;
                that.loading = true;
                that.inspirationAddOrSave(params);
            },

            inspirationAddOrSave(params){
                var that = this;
                let friends = [];
                for (const at of that.at_user_list) {
                    friends.push(at.friendInfo.uuid)
                }
                params.AT = JSON.stringify(friends);
                params.link = that.linkConent;
                params.images = JSON.stringify(that.imgs);
                params.videos = JSON.stringify(that.videos);
                params.enclosure = JSON.stringify(that.files);

                //修改原始数据
                that.data.auth = params.auth;
                that.data.content = params.content;
                that.data.iscomment = params.iscomment;
                that.data.images = that.imgs;
                that.data.videos = that.videos;
                that.data.enclosure = that.files;

                that.loading = false;
                this.newApi.inspirationAddOrSave(params).then((res) => {
                    if (res.isSuccess == 1) {
                        that.utils.sus(res.data);
                        that.loading = false;
                        that.content = "";
                        that.imgs = [];
                        that.videos = [];
                        that.files = [];
                        that.at_user_list = [];
                        that.linkConent = "";
                        that.reply_type = "1";
                        that.common_type = "1";
                        that.$parent.edit_show = false;
                        this.$EventBus.$emit("changeData", {changeData: 1});
                    }
                })
            },
            exit_save(){
                this.$parent.edit_show = false;
                //this.$EventBus.$emit("changeData", {changeData: 1});
            }
        }
    }
</script>

<style scoped>
    /deep/ .el-dialog__header{
        padding: 0;
    }

    /deep/ .el-dialog__body{
        padding: 0;
    }

    /deep/ .el-tabs--border-card .el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background-color: #031F88;
    }

    .input_box_btn{
        float:left; overflow:hidden;padding-right: 18px;
    }
    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }
    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }
    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        /*margin-top: 5px;*/
        /*margin-right: 7px;*/
    }
    .input_box_btn_icon{
        float:left; font-size:20px;line-height:38px; margin-right:5px;
    }
    .input_box_btn_name{
        float:left;
    }
    .at_user{
        float: left;margin: 2.5px 5px;width: 90px;height: 40px;overflow: hidden;padding: 0px 5px;
    }
    .at_user.active{
        float: left;margin: 2.5px 5px;width: 90px;height: 40px;background: #C7E0F4;overflow: hidden;padding: 0px 5px;
    }
    .input_box{
        position: relative;
    }
    .input_box.active{
        position: relative;box-shadow: 1px 1px 5px rgba(0,0,0,.3);
    }
    .input_box_title{
        line-height:30px; margin:0 0 10px 0;
    }
    .input_box_textarea{
        width:100%;
    }
    .fade{
        animation:fadenum 0.3s infinite;
        animation-iteration-count:1;
    }
    .input_box_btn_list{
        overflow:hidden; height:40px;line-height:40px;
    }
    .release .event-form .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 58px;
        box-sizing: border-box;
        line-height: 58px;
    }
    /deep/ .el-textarea__inner {
        background-color: #F0F0F5 !important;
        min-height: 240px !important;
    }
    /*.el-dropdown-menu{*/
    /*    position: absolute !important;*/
    /*    left: 800px !important;*/
    /*    top: 309px !important;*/
    /*}*/

    /deep/ .event-form textarea {
        background-color: #FFFFFF !important;
    }
</style>
