<template>
    <div class="dynamic-operation">
        <div class="bottom" v-if="!type">
            <div class="btn hand op" v-if="data.isCollection == 0" @click="clickshowLabel">
                <img src="../imgs/collect.png" style="width: 14px;height: auto;margin-right: 6px;">收藏
            </div>
            <div class="btn hand op" style="color: #FFA500;" v-else @click="clearCollection">
                <img src="../imgs/collect.png" style="width: 14px;height: auto;margin-right: 6px;">取消收藏
            </div>
            <div class="btn hand op" @click="toTranspond" v-if="data.isReprinted == 1 && user.uuid != data.uuid && data.bgbType < 6">
                <img src="../imgs/share.png" style="width: 14px;height: auto;margin-right: 6px;">转发
            </div>
<!--            <div class="btn" style="color: #FFA500;" v-if="data.auth == 4">-->
<!--                <img src="../imgs/share.png" style="width: 14px;height: auto;margin-right: 6px;">已转发-->
<!--            </div>-->
            <div class="btn hand op" v-if="data.isComment == 1" @click="showCommentInfo">
                <img src="../imgs/reply.png" style="width: 14px;height: auto;margin-right: 6px;">评论（{{commentNum}}）
            </div>
<!--            <div class="btn" v-else @click="showCommentMessage">-->
<!--                <img src="../imgs/reply.png" style="width: 14px;height: auto;margin-right: 6px;">评论-->
<!--            </div>-->
            <div v-if="data.isThumbsUp == 0" class="btn hand op" @click="clickLike">
                <img src="../imgs/fabulous.png" style="width: 14px;height: auto;margin-right: 6px;">点赞（{{data.thumbsUpCount}}）
            </div>
            <div v-else class="btn hand op" style="color: #FFA500;" @click="clickLike">
                <img src="../imgs/fabulous.png" style="width: 14px;height: auto;margin-right: 6px;">已点赞（{{data.thumbsUpCount}}）
            </div>
        </div>

        <div style="display: flex;" v-if="type">
            <div class="btn hand op" style="margin-right: 20px; display: flex; align-items: center" @click="toTranspond" v-if="data.isReprinted == 1 && user.uuid != data.uuid && data.bgbType < 6">
                <img src="../imgs/share.png" style="width: 14px;height: 14px; margin-right: 6px;">转发
            </div>
            <div class="btn hand op" style="margin-right: 20px; display: flex; align-items: center" v-if="data.isComment == 1" @click="showCommentInfo">
                <img src="../imgs/reply.png" style="width: 14px;height: 14px;margin-right: 6px;">评论（{{commentNum}}）
            </div>
            <div v-if="data.isThumbsUp == 0" class="btn hand op" @click="clickLike" style="; display: flex; align-items: center">
                <img src="../imgs/fabulous.png" style="width: 14px;height: 14px;margin-right: 6px;">点赞（{{data.thumbsUpCount}}）
            </div>
            <div v-else class="btn hand op" style="color: #FFA500;; display: flex; align-items: center"  @click="clickLike">
                <img src="../imgs/fabulous.png" style="width: 14px;height: 14px;margin-right: 6px;">已点赞（{{data.thumbsUpCount}}）
            </div>
        </div>

        <div class="show-comment" v-if="showComment == true">
            <el-divider></el-divider>
            <div style="display: flex; margin-bottom: 10px">
                <img :src="user.headIco" style="width: 30px; height: 30px; border-radius: 50%; margin-right: 10px"/>
                <el-input style="height: 30px; margin-right: 12px" placeholder="发布你的评论" v-model="sendContent"></el-input>
            </div>
            <div style="display: flex; justify-content: end; margin: 20px 10px">
                <span class="hand op comment-btn" v-if="sendContent !== ''" @click="addCommand">评论</span>
                <span class="hand op comment-btn" v-else style="background-color: #D8DADF" @click="addCommand">评论</span>
            </div>
            <el-divider></el-divider>
            <div v-for="(item, index) in commentData" :key="index">
                <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                    <div style="display: flex" @click="openInfo(item.user.uuid, item.user.userType)">
                        <img :src="item.user.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px; margin-top: 4px">
                        <div style=" margin-top: 2px; margin-right: 15px;">
                            <div style="font-weight: 600; color: #2970FF">{{item.user.nickName}}</div>
                            <div>{{item.content}}</div>
                            <div style="color: #999999; font-size: 8px; margin-top: 8px">{{item.creatTime}}</div>
                        </div>
                    </div>
                    <div style="display: flex">
                        <div class="hand op" style="background-color: #FF9800; color: #fff; width: 52px; height: 18px; border-radius: 5px; padding: 2px 0; font-size: 12px; text-align: center" @click="showEditor(item)">回复</div>
                      <span class="hand op" v-if="user.uuid === data.user.uuid || user.uuid === item.user.uuid" style="color: #1C7EFF; margin-left: 10px" @click="deleteContent(item.id)">删除</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between; margin-bottom: 12px; margin-left: 30px" v-for="(reply, num) in item.sub" :key="num" >
                    <div style="display: flex;" @click="openInfo(reply.user.uuid, reply.user.userType)">
                        <img :src="reply.user.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px; margin-top: 4px">
                        <div>
                            <div style=" margin-top: 2px; margin-right: 15px;">
                                <div style="font-weight: 600; color: #2970FF">{{reply.user.nickName}}</div>
                                <div>{{reply.content}}</div>
                                <div style="color: #999999; font-size: 8px; margin-top: 8px">{{reply.creatTime}}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="hand op" style="color: #1C7EFF;" @click="showEditor(item)">回复</span>
                      <span class="hand op" style="color: #1C7EFF; margin-left: 10px" v-if="user.uuid === data.user.uuid || user.uuid === reply.user.uuid" @click="deleteContent(reply.id)">删除</span>
                    </div>
                </div>
                <div v-if="item.sub.length % 10 === 0 && item.sub.length !== 0" class="hand" style="color: #1C7EFF; margin-left: 40px; margin-bottom: 10px" @click="getReply(index, item.id)">查看更多回复 <i class="el-icon-arrow-down"></i></div>
            </div>
            <div v-if="commentData.length % 10 === 0 && commentData.length !== 0" class="hand" style="color: #1C7EFF; margin-left: 42%" @click="getMoreComment">查看更多评论 <i class="el-icon-arrow-down"></i></div>
        </div>

        <el-dialog :visible.sync="showReply" :title="replyInfo.title">
            <div style="margin-top: 20px">
                <el-input style="height: 30px; margin-right: 12px" :rows="3" placeholder="发布你的评论" v-model="replyContent"></el-input>
            </div>
            <div style="display: flex; justify-content: end; margin: 20px 10px">
                <span class="hand op comment-btn" @click="addReply">回复</span>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showLabel">
            <div style="padding: 20px; display: flex">
                <span style="font-size: 18px; font-weight: 600">标签名： </span>
              <el-autocomplete
                  style="width: 400px"
                  v-model="labelName"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  @select="handleSelect">
                <template slot-scope="{ item }">
                  <div class="name">{{ item.name }}</div>
                </template>
              </el-autocomplete>
            </div>
            <div style="display: flex; justify-content: end">
                <el-button class="cel-btn" @click="cancelLabel">取消</el-button>
                <el-button class="save-btn" @click="saveLabel">确定</el-button>
            </div>
        </el-dialog>

        <!--转发-->
        <el-dialog :visible.sync="showTran">
            <Transpond :data="data" @custom-event="closeShow"></Transpond>
        </el-dialog>
    </div>
</template>

<script>
    import Transpond from "./Transpond";
    export default {
        name: "DynamicOperation",
        components: {Transpond},
        props: {
            data: {
                type: Object ,
                default() {
                    return {}
                }
            },

            type: {
                type: Number
            }
        },

        data() {
            return {
                labelName: '',
                showLabel: false,

                restaurants:[],
                loading:false,

                showComment: false,
                showReply: false,
                sendContent: '',
                replyContent: '',
                replyInfo: {
                    title: '',
                    cId: ''
                },

                page: 1,
                more: 1,
                pageSize: 10,

                commentData: [],
                replyData: [],
                commentNum: 0,

                showTran: false
            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

      created() {

        if (this.data.comment) {
          this.commentNum = this.data.comment.length
        }


      },

      mounted() {
        },

        methods: {
          // 点击打开用户信息
          openInfo(uuid, userType) {
            if (!this.utils.toAuth(1)) {
              return false
            }
            if (userType === 1) {
              this.utils.a('/circle/mySpace/' + uuid)
            }
            if (userType === 2 || userType === 3) {
              this.utils.a('/circle/organSpace/' + uuid)
            }
          },

            //  取消收藏
            clearCollection() {
              this.newApi.cancelCollection({id: this.data.id, bgbType: this.data.bgbType}).then(res => {
                if (res.isSuccess === 1) {
                  this.utils.sus(res.data)
                  this.data.isCollection = 0
                }
              })
            },

            // 关闭弹窗
            closeShow() {
                this.showTran = false;
            },
            handleSelect(item) {
              this.labelName = item.name; // 将选择的名称赋值给 labelName
            },

            async getUserCollectionLabelList() {
              try {
                const response = await this.newApi.getUserCollectionLabelList({});
                if (response.isSuccess === 1) {
                  this.restaurants = response.data;
                }
              } catch (error) {
                console.error(error);
              }
            },

            async querySearch(queryString, cb) {
              if (this.restaurants.length === 0) {
                // 如果填写的数据为空，先加载数据
                await this.getUserCollectionLabelList();
              }

              // 进行搜索
              const results = queryString
                  ? this.restaurants.filter(restaurant =>
                      restaurant.name.toLowerCase().includes(queryString.toLowerCase())
                  )
                  : this.restaurants;

              // 调用 callback 返回建议列表的数据
              cb(results);
            },

            //点击贴标签/收藏
            clickshowLabel(){
                var that = this;
                  if (!this.utils.toAuth(1)) {
                    return false
                  }
              that.showLabel = true;
            },

            // 获取回复
            getReply(index, id) {
                this.commentData[index].page ++;
                this.newApi.getCommentSub({id: id, page: this.commentData[index].page, pageSize: 10}).then(res => {
                    for (const item of res.data) {
                        this.commentData[index].sub.push(item);
                    }
                })
            },

            // 发表回复
            addReply() {
                this.newApi.addComment({type: 1, cId: this.replyInfo.cId, content: this.replyContent}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.replyContent = '';
                        this.showReply = false;
                        this.getComment();
                        this.utils.sus(res.data)
                    }
                })
            },

            // 添加评论
            addCommand() {
                this.newApi.addComment({type: 0, bgbType: this.data.bgbType, cId: this.data.id, content: this.sendContent}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.sendContent = '';
                        this.getComment();
                        this.commentNum ++
                        this.utils.sus(res.data)
                    }
                })
            },

            // 删除评论
            deleteContent(id) {
              this.newApi.delComment({id: id}).then(res =>{
                if (res.isSuccess == 1) {
                  this.getComment();
                  this.commentNum --
                  this.utils.sus(res.data)
                }
              })
            },

            // 展示回复
            showEditor(item) {
                this.showReply = true;
                this.replyInfo.title = '回复' + item.user.nickName;
                this.replyInfo.cId = item.id
            },

            // 展示评论内容
            showCommentInfo() {
                var that = this;
                if (!this.utils.toAuth(1)) {
                  return false
                }
                that.showComment = !that.showComment;
                that.getComment()


            },

            getComment() {
                this.page = 1;
                if(this.showComment === true) {
                    this.newApi.getComment({id: this.data.id, bgbType: this.data.bgbType, page: this.page, pageSize: this.pageSize}).then(res => {
                        for (const item of res.data) {
                            item['page'] = 1
                        }
                        this.commentData = res.data;
                    })
                }
            },

            // 查看更多评论
            getMoreComment() {
                this.page ++;
                this.newApi.getComment({id: this.data.id, bgbType: this.data.bgbType, page: this.page, pageSize: this.pageSize}).then(res => {
                    for (const item of res.data) {
                        item['page'] = 1;
                        this.commentData.push(item);
                    }
                })
            },

            // 未开启评论提醒
            showCommentMessage() {
                this.utils.msg('作者未开启评论！')
            },

            // 转发
            toTranspond() {
              if (!this.utils.toAuth(1)) {
                return false
              }
                this.showTran = true
                // var that = this;
                // if(that.user.realNameStatus == 0 || that.user.realNameStatus == 1){
                //     that.utils.realNameAuthJump(that.user.uuid,that.user.realNameStatus,that.user.userType);
                //     return;
                // }else{
                //     that.newApi.forward({id: that.data.id, bgbType: that.data.bgbType}).then(res =>{
                //         if (res.isSuccess == 1) {
                //             that.data.isForward = 1;
                //             that.$emit('getForwardContent');
                //             that.utils.sus(res.data)
                //         }
                //     })
                // }

            },


            // 点赞、取消点赞
            clickLike() {
                var that = this;
                    if (!this.utils.toAuth(1)) {
                      return false
                    }
                    that.newApi.thumbsUp({id: that.data.id, type: that.data.bgbType}).then(res =>{
                        if (res.isSuccess == 1) {
                            if (that.data.isThumbsUp === 0) {
                                that.data.thumbsUpCount += 1;
                                that.data.isThumbsUp = 1
                            } else {
                                that.data.thumbsUpCount -= 1;
                                that.data.isThumbsUp = 0
                            }
                        }
                    })

            },

            // 保存标签
            saveLabel() {
                this.newApi.addCollection({bgbType: this.data.bgbType, label: this.labelName, id: this.data.id}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.labelName = '';
                        this.showLabel = false;
                        this.data.isCollection = 1;
                        this.utils.sus(res.data)
                    }
                })
            },

            // 取消标签
            cancelLabel() {
                this.labelName = '';
                this.showLabel = false;
            },
        },
    }
</script>

<style scoped>
    .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2px;
        height: 30px;
        color: #444444;
        font-size: 14px;
        text-align: center;
    }

    .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }

    .dynamic-operation .save-btn {
        width: 50px;
        height: 32px;
        color: #fff;
        margin-top: 20px;
        padding: 10px;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .dynamic-operation .cel-btn {
        width: 50px;
        height: 32px;
        color: #fff;
        margin-top: 20px;
        padding: 10px;
        background-color: #6d737b;
        border-radius: 5px;
    }

    .comment-btn {
        width: 34px;
        height: 12px;
        line-height: 12px;
        padding: 8px 18px;
        border-radius: 20px;
        background-color: #FF9800;
        color: #FFFFFF;
    }

    /*公用及el*/
    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;
        transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }

</style>
