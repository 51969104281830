<template>
	<transition name="el-fade-in-linear">
		<div id="addImageFileBaseFormBg" class="fade" v-if="isShow" v-loading="loading">
			<div id="form">
				<div id="head">上传图片 <div id="close" class="po sc hand" @click="onClose">X</div> </div>


				<div id="center">
<!--					<el-upload class="upload op sc" drag multiple :auto-upload="false" action=" " :show-file-list="false" :on-change="uploadImg" accept="image/*">-->
<!--						<div class="el-upload__text">拖拽图片到这里，或<em>点此添加</em></div>-->
<!--						<p>请您确保上传的内容符合法律法规，符合公共道德</p>-->
<!--					</el-upload>-->

                    <div style="width: 100%;height: 120px;text-align: center;">
                        <el-upload class="upload op sc" drag multiple :auto-upload="false" action=" " :show-file-list="false" :on-change="uploadImg" accept="image/*">
                            <div>拖拽图片到这里，或<em style="color: #aa7700;">点此添加</em> </div>
                        </el-upload>
                    </div>

                    <div v-show="imgList.length == 0" style="width: 100%;line-height: 50px;text-align: center;">
                        <div>扫描二维码手机上传文件</div>
                        <div  style="margin: 0 auto;">
                            <vue-qr
                                :text="qr_imgUrl + 'uploadImages?uuid=' + user.uuid + '&type=13' + '&sign=' + sign"
                                :size="250"
                                :logoSrc="qr_logo"
                                :logoScale="0.2">
                            </vue-qr>
                        </div>
                    </div>

                    <div v-show="imgList.length > 0" style="width: 100%;line-height: 30px;text-align: center;margin-top: 10px;">
                        <div>扫描二维码手机上传图片<em @mouseenter="isCodeFile = true"  style="color: #aa7700;">点此添加</em> </div>
                        <el-dialog :close-on-click-modal="false" :visible.sync="isCodeFile" width="300px">
                            <vue-qr
                                :text="qr_imgUrl + 'uploadImages?uuid=' + user.uuid + '&type=7' + '&sign=' + sign"
                                :size="250"
                                :logoSrc="qr_logo"
                                :logoScale="0.2">
                            </vue-qr>
                        </el-dialog>
                    </div>

					<div id="panel" v-show="imgList.length > 0">
						<div id="panelLeft">
							<div id="imgList">
								<div class="imgFile" v-for="(item,index) in imgList" :class="{'active':item === activeImg}">
									<img v-if="item.url !== ''" :src="item.url" class="op sc hand" @click="activeImg = item" >
									<div class="progress" v-if="item.url === ''">
										<el-progress :percentage="item.progress" :show-text="false"></el-progress>
										<p>{{item.progress}}%</p>
									</div>
									<div class="isCoverImage" v-if="item.isCoverImage">封面</div>
									<div class="describe op sc hand" @click="activeImg = item" v-if="item.describe">{{item.describe}}</div>
									<div class="remove op sc hand" @click="imgList.splice(index,1)">x</div>
								</div>

							</div>
						</div>
                        <div id="panelRight">
                            <img id="preViewImg" :src="activeImg.url">
                            <div>
                                <el-input
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="activeImg.describe">
                                </el-input>

                                <el-switch active-text="设为封面" v-model="activeImg.isCoverImage" @change="coverImageChange"></el-switch>
                            </div>

                        </div>
					</div>
				</div>


				<div id="bottom" style="float:right;margin-right: 15px;">
					<label >
						<el-button type="primary" icon="el-icon-position" class="button op sc hand" @click="submit">完成</el-button>
					</label>

				</div>
			</div>

		</div>
	</transition>
</template>

<script>


	export default {
		name: "addImageFileBaseForm",
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
		data(){
			return{
				activeImg:{},
				loading:false,
				imgList:this.images,
                //扫码上传文件
                isCodeFile:false,
                qr_imgUrl:this.$store.state.mobileUrl,
                qr_logo: require('@/assets/imgs/other/bgb.png'),
                sign:this.utils.random_string(6),
			}
		},
		props:{
			images:{
				type:Array,
				default(){
					return [];
				}
			},
			isShow:{
				type:Boolean,
				default(){
					return false;
				}
			},
			onClose:{
				type:Function,
				default(){

				}
			}

		},
		mounted() {
            var that = this;
			for (let i = 0;i < this.imgList.length; i ++){
				if(this.imgList[i].isCoverImage == true){
					this.activeImg = {
						progress:0,
						isCoverImage:true,
						url:this.imgList[i].url,
						describe:this.imgList[i].describe
					};
				}
			}

            this.$EventBus.$EventBus.$off("callbackNotice");
            this.$EventBus.$on("callbackNotice", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        let obj = JSON.parse(data.data);
                        if(obj.type == 1001){
                            that.isCodeFile = false;
                            that.getCodeFiles(obj);
                        }else if(obj.type == 1002){
                            that.delCodeFiles(obj);
                        }
                    }
                })
            })
		},
		methods: {
            getCodeFiles(obj){
                var that = this;
                const id = that.utils.random_string();
                let param = {
                    id:id,
                    progress:0,
                    isCoverImage:true,
                    url:"",
                    describe:"",
                };
                if(this.imgList.length > 0){
                    param.isCoverImage = false;
                }
                this.activeImg = param;
                this.imgList.push(this.activeImg);

                //预载一下图片防止加载不丝滑
                const img = new Image();
                img.src = obj.url;
                img.onload = function (){
                    that.setUrl(id,obj.url,obj.hash);
                }
            },

            delCodeFiles(obj){
                var that = this;
                for (let i = 0; i < that.imgList.length; i++) {
                    if(that.imgList[i].hash == obj.hash){
                        that.imgList.splice(i,1);
                        that.utils.sus("删除成功");
                        break;
                    }
                }
            },

            uploadImg(file){
				const that = this;
				const id = this.utils.random_string();
				let param = {
					id:id,
					progress:0,
					isCoverImage:true,
					url:"",
					describe:"",
				};
				if(this.imgList.length > 0){
					param.isCoverImage = false;
				}
				this.activeImg = param;
				this.imgList.push(this.activeImg);
				this.utils.upload(file.raw,function (ret){
					if (!ret){
						return false;
					}
					//预载一下图片防止加载不丝滑
					const img = new Image();
					img.src = ret.url;
					img.onload = function (){
						that.setUrl(id,ret.url,ret.hash,file.name);
					}

				},function (p){
					that.setProgress(id,p)
				})
			},
			setUrl(id,url,hash,name){
				for (let i = 0; i < this.imgList.length; i++) {
					if (this.imgList[i].id === id){
						this.imgList[i].url = url;
						this.imgList[i].hash = hash;
						this.imgList[i].name = name;
					}
				}
			},
			setProgress(id,progress){
				for (let i = 0; i < this.imgList.length; i++) {
					if (this.imgList[i].id === id){
						this.imgList[i].progress = progress;
					}
				}
			},
			coverImageChange(val){
				if(this.imgList.length == 1){
					this.utils.msg("最少要有一张封面");
					return false;
				}
				for (let i = 0; i < this.imgList.length; i++) {
					this.imgList[i].isCoverImage = false;
					if(this.imgList[i].url == this.activeImg.url){
						this.imgList[i].isCoverImage = true;
					}
				}
				this.activeImg.isCoverImage  = true;
			},
			submit(){
				const that = this;
				if (this.imgList.length == 0){
					this.utils.err("请上传至少一张图片");return;
				}

				const params = {};

				this.imgList.forEach((item,index)=>{
					this.imgList[index] = {
						url:item.url,
						hash:item.hash,
						name:item.name,
						describe:item.describe,
						isCoverImage:item.isCoverImage,
					}
					if(item.url == that.activeImg.url){
						this.imgList[index].describe = that.activeImg.describe;
					}
				})
				params.images = this.imgList;
				this.onClose();
				this.$emit('custom-event', params); // 触发自定义事件并传递参数
			},
		},
	}
</script>

<style >
.v-modal{
    z-index: 0 !important;
}

</style>
