<template>
        <div  style="width: 100%;height: 100%">
            <div @click="contentEnclosure()" class="close po sc hand" style="float: right;margin-right: 10px;margin-top: 5px;">X</div>
            <el-tabs v-model="file_tag_name"  type="border-card">
                <div class="dragger-file">
<!--                    <el-upload accept=".pdf, .doc, .docx, .xls, .xlsx"  class="upload-demo"   action="" drag multiple :http-request="upload_file" :show-file-list="false">-->
<!--                        <i class="el-icon-upload"></i>-->
<!--                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>-->
<!--                    </el-upload>-->
                    <div style="width: 100%;height: 60px;text-align: center;">
                        <el-upload  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"    action="" drag multiple :http-request="upload_file" :show-file-list="false" >
                            <div>拖拽文件到这里，或<em style="color: #aa7700;">点此添加</em> </div>
                        </el-upload>
                    </div>
                    <div v-show="files.length == 0" style="width: 680px;height: 230px;text-align: center;">
                        <div>扫描二维码手机上传附件</div>
                        <div  style="margin: 0 auto;margin-top: 20px;">
                            <vue-qr
                                :text="qr_imgUrl + 'uploadFile?uuid=' + user.uuid + '&type=15' + '&sign=' + sign"
                                :size="200"
                                :logoSrc="qr_logo"
                                :logoScale="0.2">
                            </vue-qr>
                        </div>
                    </div>
                </div>

            </el-tabs>
            <div style="overflow: hidden; height:20px;line-height:20px; padding: 0 16px; margin-bottom: 10px">
                <div style="float:left;color:#acacac;">
                    附件:（{{files.length}}）<!-- /9 -->
                </div>
            </div>
            <div style="overflow:hidden;">
                <div class="video" v-for="(item,index) in files" :key="index" style="width:80px;height:100px;border-radius:3px; float:left; margin:5px; background-size:100%;position:relative;" :title="item.file_name">
                    <div style="width:100%;height:calc(100% - 20px);background-color: rgba(0,0,0,.3);">
                        <img :src="ossUrl+'img/file.png'" style="width:100%;height:100%;" />
                    </div>
                    <div style="height:20px;width:70px;margin: 0 5px;line-height: 20px;text-align: center;word-break:keep-all;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{item.name}}</div>
                    <div style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px; cursor:pointer;user-select: none;position:absolute;right:0;top:0;" @click="removeFile(index)">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: flex-end;">
                <el-button style="width: 80px; height: 34px; border-radius: 5px; margin: 10px 20px" @click="contentEnclosure()">取消</el-button>
                <el-button style="width: 80px; height: 34px; border-radius: 5px; background-color: #031F88; color: #fff; margin: 10px 16px 10px 0" @click="add_attachment">完成</el-button>
            </div>
        </div>
</template>
<script>

export default {
    components: {

    },

    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    data () {
        return {
            files:this.data,
            file_tag_name:"pc",
            //扫码上传文件
            qr_imgUrl:this.$store.state.mobileUrl,
            qr_logo: require('@/assets/imgs/other/bgb.png'),
            sign:this.utils.random_string(6),
        }
    },
    props :{
        data:{
            type:Array,
            default(){
                return [];
            }
        },
    },
    watch: {

    },
    mounted:function(){
        var that = this;
        this.$EventBus.$EventBus.$off("callbackNotice");
        this.$EventBus.$on("callbackNotice", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    let obj = JSON.parse(data.data);
                    if(obj.type == 1001){
                        that.isCodeFile = false;
                        that.getCodeFiles(obj);
                    }else if(obj.type == 1002){
                        that.delCodeFiles(obj);
                    }
                }
            })
        })
    },
    methods:{
        getCodeFiles(obj){
            var that = this;
            var obj = {
                name:obj.name,
                url:obj.url,
                hash:obj.hash
            };
            that.files.push(obj);
        },

        delCodeFiles(obj){
            var that = this;
            for (let i = 0; i < that.files.length; i++) {
                if(that.files[i].hash == obj.hash){
                    that.files.splice(i,1);
                    that.utils.sus("删除成功");
                    break;
                }
            }
        },

        //附件上传
        upload_file:function(params){
            var that = this;
            var file = params.file;
            that.openLoading('正在上传请稍后')
            that.utils.upload(file,function(url){
	            if (!url){
		            return false;
	            }
                var obj = {
                    name:url.name,
                    url:url.url,
                    hash:url.hash
                };
                that.files.push(obj);
            })
        },
        //移除附件
        removeFile:function(index){
            var that = this;
            that.utils.confirm("移除这个附件？",function(){
                that.files.splice(index, 1);
            })
        },
        add_attachment(){
            var that =this;
            if (that.files.length == 0){
                that.utils.err("请上传附件");
                return false;
            }
            that.contentEnclosure();

        },
        contentEnclosure(){
            var that =this;
            var params = {};
            params.files = that.files;
            this.$emit('custom-event',params);
        },
    }
}
</script>
<style  scoped>
     /deep/  .dragger-file .upload-demo .el-upload-dragger {
         width: 660px !important;
         margin-top: 10px;
     }
     /deep/ .el-checkbox__inner {
         width: 16px;
         height: 17px;
     }
     /deep/ .el-checkbox__inner::after {
         left: 5px;
         top: 1px;
         height: 10px;
     }
     /deep/ .el-checkbox__label {
         font-size: 16px;
     }
    /deep/ .el-upload-dragger{
        width: 680px;
    }
     /deep/ .el-upload-dragger {
         border: 0px;
         background: none;
         height: 30px;
         margin-top: 10px;
     }
     /deep/ .el-dialog__header{
         padding: 0px;
     }
</style>
