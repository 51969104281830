<template>
    <div style="width: 100%;height: 100%;padding: 0px">
       <div style="height: 24px;font-size: 18px;color: #444444;font-weight:bold;display: flex;justify-content: center">上传视频</div>
        <div @click="contentvideo()" class="close po sc hand">X</div>
        <div style="border: 1px solid #CECFD0;width: 100%;margin-top: 5px;"></div>
       <template>
           <div style="height: 330px">
               <div v-if="videos == '' "  style="justify-content: center;">
<!--                   <el-upload accept="video/*" ref="clearawayvideo"  style="margin-top: 50px;"  class="upload-demo" action="" drag  :http-request="upload_video" :show-file-list="false">-->
<!--                       <i class="el-icon-upload" style="font-size: 96px;"></i>-->
<!--                       <div class="el-upload__text">拖拽视频到这里，或<em style="color:#F98B15">点此添加</em></div>-->
<!--                       <div style="font-size: 14px;color:#999999">请您确保上传的视频内容符合法律法规，符合公共道德</div>-->
<!--                   </el-upload>-->
                   <div style="width: 100%;line-height: 40px;text-align: center;">
                       <el-upload accept="video/*" ref="clearawayvideo"   style="margin-top: 20px;"  class="upload-demo" action="" drag  :http-request="upload_video" :show-file-list="false">
                           <div>拖拽视频到这里，或<em style="color: #aa7700;">点此添加</em> </div>
                       </el-upload>
                   </div>

                   <div v-show="videos.length == 0" style="width: 100%;line-height: 20px;text-align: center;">
                       <div>扫描二维码手机上传视频</div>
                       <div  style="margin: 0 auto;margin-top: 20px;">
                           <vue-qr
                               :text="qr_imgUrl + 'uploadVideo?uuid=' + user.uuid + '&type=14' + '&sign=' + sign"
                               :size="200"
                               :logoSrc="qr_logo"
                               :logoScale="0.2">
                           </vue-qr>
                       </div>
                   </div>

               </div>
               <div style="overflow:hidden;" v-if="videos.length > 0">
                   <div v-for="(item,index) in videos" :key="index" :style="{backgroundImage:'url('+item.img_url}" style="width:424px;height:272px;float:left;background-size:100% 100%;position:relative;margin:15px 30px;border-radius:5px;">
                       <div style="width:100%;height:100%;background-color: rgba(0,0,0,.3);">
                           <img :src="ossUrl + 'img/video_play.png'" style="width:60px;height:60px;position: absolute;top: 45%;left: 45%;border-radius:5px;" />
                       </div>
                       <div style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px; cursor:pointer;user-select: none;position:absolute;right:0;top:0;" @click="removeVideo(index)">
                           <i class="el-icon-close"></i>
                       </div>
                   </div>
               </div>
           </div>
       </template>

        <div style="border: 1px solid #CECFD0;width: 100%;margin-top: 5px;"></div>
        <div style="display: flex;justify-content: center;margin-top: 15px">
           <div @click="contentvideo"><el-button plain>取消</el-button></div>
           <div style="margin-left: 10px;"><el-button @click="addvideo()" type="primary" class="el-icon-s-promotion">完成</el-button></div>
        </div>
    </div>
</template>
<script>

export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    data () {
        return {
            videos:this.video,
            removeVideos:[],
            //扫码上传文件
            qr_imgUrl:this.$store.state.mobileUrl,
            qr_logo: require('@/assets/imgs/other/bgb.png'),
            sign:this.utils.random_string(6),
        }
    },
    props :{
        video:{
            type:Array,
            default(){
                return [];
            }
        },
    },
    created:function(){

    },
    mounted:function(){
        var that = this;
        this.$EventBus.$EventBus.$off("callbackNotice");
        this.$EventBus.$on("callbackNotice", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    let obj = JSON.parse(data.data);
                    if(obj.type == 1001){
                        that.isCodeFile = false;
                        that.getCodeFiles(obj);
                    }else if(obj.type == 1002){
                        that.delCodeFiles(obj);
                    }
                }
            })
        })
    },
    methods:{
        getCodeFiles(obj){
            var that = this;
            let pa = {
                name:obj.name,
                img_url:obj.url + "?x-oss-process=video/snapshot,t_0,f_jpg",
                url:obj.url,
                hash:obj.hash,
            };
            that.videos.push(pa);
        },

        delCodeFiles(obj){
            var that = this;
            for (let i = 0; i < that.videos.length; i++) {
                if(that.videos[i].hash == obj.hash){
                    that.videos.splice(i,1);
                    that.utils.sus("删除成功");
                    break;
                }
            }
        },

        // 上传视频
        upload_video(params) {
            var file = params.file;
            var that = this;

            if (file.type.indexOf('video') > -1) {
                that.utils.upload(file,function(url) {
                    if (!url){
                        return false;
                    }
                    let pa = {
                       name:url.name,
                       img_url:url.url + "?x-oss-process=video/snapshot,t_0,f_jpg",
                       url:url.url,
                       hash:url.hash,
                    };

                    that.videos.push(pa);
                })
            }else{
                this.$message.error('只能上传视频！')
                return false
            }
        },
        removeVideo:function(index){
            var that = this;
            that.utils.confirm("移除这个视频？",function(){
                that.removeVideos.push(that.videos[index]);
                that.videos.splice(index,1);
                that.spaceSize = that.spaceSize - that.videos[index].size;
            })
        },
        addvideo (){
            var that =this;
            if (that.videos.length == 0){
                that.utils.err("请上传视频");
                return false;
            }
            that.contentvideo();
        },

        contentvideo(){
            var that =this;
            var params = {};
            params.videos = that.videos;
            this.$emit('custom-event',params);
        },

    }
}
</script>
<style scoped>
.close {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #E4E6EB;
    position: absolute;
    right: 15px;
    top: 18px;
    line-height: 34px;
    text-align: center;
    color: #707070;
}
/deep/ .el-upload-dragger {
    border: 0px dashed #d9d9d9
}
.label {
    width: 100px;
    height: 40px;
    font-size: 18px;
    margin-top: 15px;
    margin-left: 30px;
}
em{
    color:#FF0000;
}
.formItem {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 20px;
}
.spanp {
    margin-left: 30px;
    font-size: 16px;
    color: #444444;
    font-weight: bold;
}
/deep/ .el-upload-dragger {
    border: 0px;
    background: none;
    height: 30px;
}

</style>
